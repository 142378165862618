import { render, staticRenderFns } from "./notes.html?vue&type=template&id=d3ebc82e&scoped=true&"
import script from "./notes.js?vue&type=script&lang=js&"
export * from "./notes.js?vue&type=script&lang=js&"
import style0 from "./notes.scss?vue&type=style&index=0&id=d3ebc82e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d3ebc82e",
  null
  
)

export default component.exports